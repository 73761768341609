<template>
  <div class="ma-auto">
    <div class="text-h4 pa-4 font-weight-bold text-center" style="color: #643a6b">家庭聯絡簿</div>
    <v-card elevation="0" class="mx-auto pa-2" color="#f2f2f2" width="90%">
      <div class="text-h5 font-weight-bold" style="color: #643a6b">健康資訊</div>
      <v-divider class="my-2" />
      <v-row no-gutters justify="center" align="center">
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartHeartRate :data="dataHeartRate" :label="labelDate" />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartBP :dataShrink="dataShrink" :dataRelax="dataRelax" :label="labelDate" />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartTemperature :dataTemperature="dataTemperature" :label="labelDate" />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartBloodSugar :dataBloodSugar="dataBloodSugar" :label="labelDate" />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartSpO2 :dataSpO2="dataSpO2" :label="labelDate" />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartWeight :dataWeight="dataWeight" :label="labelDate" />
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0" class="mx-auto pa-2" color="#f2f2f2" width="90%">
      <div class="text-h5 font-weight-bold" style="color: #643a6b">交通車位置</div>
      <v-divider class="my-2" />
      <v-row no-gutters justify="center" align="center">
        <v-col cols="12">
          <l-map style="height: 500px" :zoom="zoom" :center="mapCenter">
            <l-tile-layer :url="tileLayerUrl" :attribution="tileLayerAttribution"></l-tile-layer>
            <l-marker
              v-for="(stop, index) in stops"
              :key="index"
              :lat-lng="stop.coordinates"
              :icon="stopIcon"
            >
              <l-popup>{{ stop.name }}</l-popup>
            </l-marker>
            <l-polyline
              :lat-lngs="actualRoute"
              color="blue"
              :weight="5"
              dash-array="15"
              line-cap="round"
              line-join="bevel"
            ></l-polyline>
            <l-marker :lat-lng="busPosition" :icon="busIcon">
              <l-popup>交通車位置</l-popup>
            </l-marker>
          </l-map>
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0" class="mx-auto my-2 pa-2" color="#f2f2f2" width="90%">
      <div class="text-h5 font-weight-bold" style="color: #643a6b">注意事項</div>
      <v-divider class="my-2" />

      <!-- 顯示留言 -->
      <div class="message-list">
        <v-row v-for="(message, index) in messages" :key="index" class="my-2">
          <!-- 機構員工的留言，顯示在左側 -->
          <v-col v-if="message.commenter === 'employee'" cols="12" class="text-left">
            <v-card class="pa-2 staff-message" color="#e3f2fd">
              <div class="font-weight-bold">機構員工：</div>
              <div>{{ message.comment }}</div>
              <small class="timestamp text-right">
                {{ formatDate(message.created) }}
                {{ formatTime(message.created) }}
                <small v-if="message.confirm" style="text-align: right">已讀</small>
              </small>
            </v-card>
          </v-col>

          <!-- 家屬的留言，顯示在右側 -->
          <v-col v-else-if="message.commenter === 'family'" cols="12" class="text-right">
            <v-card class="pa-2 family-message" color="#ffebee">
              <div class="font-weight-bold">家屬：</div>
              <div>{{ message.comment }}</div>
              <div class="timestamp text-left">
                {{ formatDate(message.created) }}
                {{ formatTime(message.created) }}
                <small v-if="message.confirm" style="text-align: right">已讀</small>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-divider class="my-2" />

      <!-- 輸入留言 -->
      <v-textarea
        v-model="newMessage"
        label="請輸入留言..."
        auto-grow
        outlined
        color="#643a6b"
      ></v-textarea>

      <!-- 提交按鈕 -->
      <v-btn color="#643a6b" dark @click="submitMessage"> 提交留言 </v-btn>
    </v-card>

    <v-card elevation="0" class="mx-auto my-2 pa-2" color="#f2f2f2" width="90%">
      <v-row dense>
        <div class="text-h5 font-weight-bold" style="color: #643a6b">預約探視與請假</div>
        <!-- <v-btn class="mx-4 white--text" color="#313866" @click="handleDialog">
          <v-icon>mdi-calendar-plus</v-icon>
          新增
        </v-btn> -->
      </v-row>
      <v-divider class="my-2" />
      <v-row dense>
        <v-col v-for="event in events" :key="event.id" cols="12" sm="6" md="4" lg="3">
          <v-card class="pa-1" :disabled="event.past" :color="event.past ? '#b6b6b6' : ''">
            <v-row dense justify="center">
              <v-col cols="4" class="text-center">
                <div class="text-subtitle-1 font-weight-bold">
                  {{ formatDate(event.start) }}
                </div>
                <div>{{ formatTime(event.start) }}</div>
                <div>-</div>
                <div>{{ formatTime(event.end) }}</div>
              </v-col>
              <v-col cols="8" class="px-4 align-self-center">
                <div class="text-h6 font-weight-bold">
                  {{ event.description }}
                </div>
                <div class="text-caption font-weight-bold">
                  <v-chip
                    class="white--text"
                    :color="
                      event.event_type === '隔離'
                        ? '#0E21A0'
                        : event.event_type === '探視'
                        ? '#4D2DB7'
                        : event.event_type === '請假'
                        ? '#9D44C0'
                        : '#EC53B0'
                    "
                    x-small
                  >
                    {{ event.event_type }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-2">
        <v-card-text class="pa-2">
          <v-row dense>
            <v-col cols="12" class="pa-2">
              <v-select
                outlined
                dense
                :items="eventTypes"
                label="事件類型"
                hide-details=""
                v-model="event_type"
              ></v-select></v-col
            ><v-col cols="12" class="pa-2">
              <v-text-field
                outlined
                dense
                label="事由"
                hide-details=""
                v-model="description"
              ></v-text-field> </v-col
            ><v-col cols="12" class="pa-2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="date"
                    label="選擇日期"
                    hide-details=""
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
              </v-menu> </v-col
            ><v-col cols="12" class="pa-2">
              <v-select
                outlined
                dense
                :items="timeOptions"
                label="選擇時間"
                hide-details=""
                v-model="time"
              ></v-select
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-2 justify-center">
          <v-btn color="blue" class="white--text" @click="addEvents">送出</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment'
import ChartBloodSugar from '@/components/ChartBloodSugar.vue'
import ChartBP from '@/components/ChartBP.vue'
import ChartHeartRate from '@/components/ChartHeartRate.vue'
import ChartSpO2 from '@/components/ChartSpO2.vue'
import ChartTemperature from '@/components/ChartTemperature.vue'
import ChartWeight from '@/components/ChartWeight.vue'
import { LMap, LTileLayer, LMarker, LPolyline, LPopup } from 'vue2-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default {
  components: {
    ChartBloodSugar,
    ChartBP,
    ChartHeartRate,
    ChartSpO2,
    ChartTemperature,
    ChartWeight,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LPopup,
  },
  data() {
    return {
      id: '',
      name: '',
      dataBloodSugar: [],
      dataShrink: [],
      dataRelax: [],
      dataHeartRate: [],
      dataSpO2: [],
      dataTemperature: [],
      dataWeight: [],
      labelDate: [],
      events: [],
      dialog: false,
      eventTypes: ['送醫', '請假', '探視', '隔離'],
      event_type: null,
      description: '',
      date: new Date().toLocaleDateString().replaceAll('/', '-'),
      menu: false,
      timeOptions: this.generateTimeOptions(),
      time: null,
      messages: [],
      newMessage: '',
      zoom: 16,
      mapCenter: [25.068244177284676, 121.49636747447765], // 地圖中心點
      tileLayerUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      tileLayerAttribution:
        "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
      stops: [
        { name: 'A', coordinates: [25.068892509426888, 121.49070162649532] },
        { name: 'B', coordinates: [25.068365068226072, 121.49637811369674] },
        { name: 'C', coordinates: [25.07, 121.5] },
      ],
      stopIcon: L.icon({
        iconUrl: require('@/assets/bus-stop.png'),
        iconSize: [50, 50],
      }),
      busPosition: [25.068892509426888, 121.49070162649532],
      busIcon: L.icon({
        iconUrl: require('@/assets/bus.png'),
        iconSize: [50, 50],
      }),
      actualRoute: [],
    }
  },
  methods: {
    async getWeeklyData() {
      const now = new Date()
      let formattedTime = moment(now).format()
      const encodedISOString = encodeURIComponent(formattedTime)
      const axios = require('axios')
      let config = {
        method: 'get',
        url: '/api/attendance/contacts/' + this.id + '/?datetime=' + encodedISOString,
        header: {},
      }
      try {
        let res = await axios.request(config)
        console.log(res.data)
        if (res.data.vital_sign.length > 0) {
          res.data.vital_sign.forEach((element) => {
            let datetime = element.start
            datetime = moment(datetime).local().format('MM/DD')
            this.labelDate.push(datetime)
            this.dataBloodSugar.push(element.blood_sugar)
            this.dataShrink.push(element.shrink)
            this.dataRelax.push(element.relax)
            this.dataHeartRate.push(element.hr)
            this.dataSpO2.push(element.SPO2)
            this.dataTemperature.push(element.temperature)
            this.dataWeight.push(element.weight)
          })
        }
        this.events = res.data.event
        this.messages = res.data.comment
      } catch (error) {
        console.log(error)
      }
    },
    async addEvents() {
      let start = moment(this.date + ' ' + this.time, 'YYYY-M-DD HH:mm').toDate()
      let end = moment(start).add(1, 'hours').toDate()
      let formattedStart = moment(start).format()
      let formattedEnd = moment(end).format()
      const encodedStart = encodeURIComponent(formattedStart)
      const encodedEnd = encodeURIComponent(formattedEnd)
      const axios = require('axios')
      const qs = require('qs')
      let data = qs.stringify({
        event_type: this.event_type,
        description: this.description,
        start: formattedStart,
        end: formattedEnd,
      })
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `/api/attendance/events/${this.id}/`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      }
      try {
        let res = await axios.request(config)
        console.log(res.data)
        this.getWeeklyData()
        this.dialog = false
      } catch (error) {
        console.log(error)
      }
    },
    async submitMessage() {
      const axios = require('axios')
      const qs = require('qs')
      let data = qs.stringify({
        comment: this.newMessage,
      })
      try {
        let res = await axios.post(`/api/attendance/comment/${this.id}/`, data)
      } catch (error) {
        console.log(error)
      }
      this.getWeeklyData()
      this.newMessage = ''
    },
    handleDialog() {
      this.event_type = ''
      this.description = ''
      this.date = new Date().toLocaleDateString().replaceAll('/', '-')
      this.time = ''
      this.dialog = true
    },
    generateTimeOptions() {
      const options = []
      for (let i = 8; i <= 18; i++) {
        const time = i < 10 ? `0${i}:00` : `${i}:00`
        options.push(time)
      }
      return options
    },
    formatDate(dateTimeStr) {
      const date = new Date(dateTimeStr)
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${month}/${day}`
    },
    formatTime(dateTimeStr) {
      const date = new Date(dateTimeStr)
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      return `${hours}:${minutes}`
    },
    async fetchRoute() {
      const apiKey = '5b3ce3597851110001cf6248332ed748e38e4b2fa3f9a3ee4c985584' // OpenRouteService API key
      const coordinates = this.stops.map((stop) => [stop.coordinates[1], stop.coordinates[0]]) // 轉換成(經度,緯度)

      try {
        const response = await fetch(`https://api.openrouteservice.org/v2/directions/driving-car`, {
          method: 'POST',
          headers: {
            Authorization: apiKey,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ coordinates }),
        })

        const data = await response.json()

        if (data && data.routes && data.routes.length > 0) {
          const geometry = data.routes[0].geometry
          this.actualRoute = this.decodePolyline(geometry)
        }
      } catch (error) {
        console.error('Error fetching route:', error)
      }
    },
    decodePolyline(encoded) {
      let points = []
      let index = 0,
        lat = 0,
        lng = 0

      while (index < encoded.length) {
        let b,
          shift = 0,
          result = 0
        do {
          b = encoded.charCodeAt(index++) - 63
          result |= (b & 0x1f) << shift
          shift += 5
        } while (b >= 0x20)
        const dlat = result & 1 ? ~(result >> 1) : result >> 1
        lat += dlat

        shift = 0
        result = 0
        do {
          b = encoded.charCodeAt(index++) - 63
          result |= (b & 0x1f) << shift
          shift += 5
        } while (b >= 0x20)
        const dlng = result & 1 ? ~(result >> 1) : result >> 1
        lng += dlng

        points.push([lat / 1e5, lng / 1e5])
      }
      return points
    },
  },
  mounted() {
    this.getWeeklyData()
    this.fetchRoute()
  },
  created() {
    this.id = this.$route.params.id
  },
}
</script>
<style scoped>
.message-list {
  max-height: 600px;
  overflow-y: auto; /* 如果留言過多，添加滾動條 */
  max-width: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-size: 1.5em;
}

/* 機構員工的留言樣式 */
.staff-message {
  max-width: 60%;
  margin-left: 1;
  float: left;
}

/* 家屬的留言樣式 */
.family-message {
  max-width: 60%;
  margin-right: 1;
  float: right; /* 讓家屬的留言靠右對齊 */
}

/* 時間戳 */
.timestamp {
  font-size: 12px;
  color: #888; /* 灰色淺字 */
  margin-top: 5px;
}

/* Leaflet */
.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.leaflet-marker-icon {
  background-size: contain;
  background-repeat: no-repeat;
}

.leaflet-marker-shadow {
  display: none;
}
</style>
